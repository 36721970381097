:root{
    --green:#008833;
    --bs-primary: #008833;
    --grid-layout:1fr 1fr 1fr 1fr;
    --border: #73be8e75;
    --shadow-sidebar: #00A841;
}
#root{
    background-color: #f4f4f4;
}
a{
    color: var(--green) !important;
    text-decoration: unset;
}
.bg-grey{
    background-color: #f4f4f4;
}
*::-webkit-scrollbar {
    display: none;
 }
 * {
    -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
 }
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--green);
    --bs-btn-border-color: var(--green);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--green);
    --bs-btn-hover-border-color: var(--green);
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--green);
    --bs-btn-active-border-color: var(--green);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--green);
    --bs-btn-disabled-border-color: var(--green);
}
.form-check-input:checked {
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}
input[type="range"]::-webkit-slider-thumb {
    background-color: var(--bs-primary) !important;
    color: red !important;
  }
input:focus, textarea:focus, label:focus, .btn:focus{
    box-shadow: 0 0 0 0.25rem rgba(3, 79, 12, 0.119) !important;
    border-color: var(--green) !important;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: var(--bs-primary);
    color: #fff !important;
}
.dish-accordion.accordion-button:not(.collapsed) {
    background: white;
    color: black;
    border: 2px solid #fff;
}
.accordion-item:last-of-type .accordion-collapse {
    border-radius: unset !important;
    background: white;
}
.accordion-item:first-of-type .accordion-button {
    border-radius: unset !important;
}
.accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: unset !important;
}
button.accordion-button {
    padding: 5px;
    background: #fff;
    color: #000 ;
    font-size: 14px;
    font-weight: 400;
    border: 2px solid #fff;
    transition: unset !important;
}
.accordion-button:focus {
    border-color: unset !important;
    box-shadow: unset !important;
}
.accordion-body {
    padding: unset;
}
.accordion-item{
    border: unset;
}
.exploded {
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50vw !important;
    height: 80vh !important;
    min-height: 90%;
    z-index: 99999;
}

.table-container.exploded{
    padding: 20px !important;
}

.exploded > .btn-exploded{
    display: block !important;
}

.btn-exploded{
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    background: #0000008f;
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}
.btn span{
    border-radius: 3px;
    border: solid white 2px;
    padding: 0px 6px;
    margin: 5px;
}
body{
    background-color: #f4f4f4;
}
p{
    margin: 0px;
    padding: 0px;
}
p:empty{
    display: none;
}
.left{
    grid-area: left;
    overflow: hidden;
}
.center{
    grid-area: center;
    overflow: hidden;
}
.right{
    grid-area: right;
    overflow: hidden;
}
.main-grid {

    width:100%;
    
    height: 100vh;
    
    display:grid;
    
    grid-template-columns: 120px auto 120px;
    
    grid-template-rows: 100%;
    
    grid-template-areas:"left center right"
    
                        "left center right"
    
                        "left center right";
    
}
.sidebar{
    position:relative;
    background-color:var(--shadow-sidebar);
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 50px 0px;
    overflow: scroll;
    padding-bottom: 140px;
    -webkit-mask-image: linear-gradient(1deg, transparent 0%,black 5%, black 90%, transparent 100%);
}
    .left .sidebar{
    -moz-box-shadow:    inset -16px 0px 30px 0px #0000002e;
    -webkit-box-shadow: inset -16px 0px 30px 0px #0000002e;
    box-shadow:        inset -16px 0px 30px 0px #0000002e;
    }
        .side-element.active{
            background-color: #fff;
            color: black !important;
            font-weight: 500;
        }
    .right .sidebar{
        -moz-box-shadow:    inset 16px 0px 30px 0px #0000002e;
        -webkit-box-shadow: inset 16px 0px 30px 0px #0000002e;
        box-shadow:        inset 16px 0px 30px 0px #0000002e;
    }
    .left, .right{
        background-color: var(--bs-primary);
        position: relative;
    }
.sidebar .side-element{
    color: black;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: .2s;
    margin: 3px 5px;
    border-radius: 20px;
    border: solid 2px black;
}
.sidebar-title {
    color: #fff;
    font-weight: 500;
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    z-index: 1;
    text-align: center;
}
.tab-bar{
    grid-area: tab-bar;
	padding: 10px 40px;
	width: calc(100% - 400px);
}
.drawer{
    grid-area: drawer;
    padding: 0px 10px;
    overflow-y: scroll;
    border-bottom: 1px solid rgb(220, 220, 220);
    /*-webkit-mask-image: linear-gradient(1deg, transparent 0%,black 5%, black 90%, transparent 100%);*/
}
    .drawer-container {
        grid-gap: 20px;
        display: grid;
        flex-wrap: wrap;
        gap: 5px;
        grid-template-columns: var(--grid-layout);
    }
.controller{
    display: flex;
    position: fixed;
    grid-area: controller;
    justify-content: center;
    bottom: -5px;
    width: 100%;
    left: 0;
    z-index: 10;
}
.center-grid {
    width:100%;
    height:100vh;
    display:grid;
    grid-template-columns: 100%;
    grid-template-rows: 65px auto 75px;
    grid-template-areas:"tab-bar"
                        "drawer"
                        "controller";
}



.center-grid-tablet {
    width:100%;
    height:100vh;
    display:grid;
    grid-template-columns: 100%;
    grid-template-rows: 95px calc(100% - 300px) 120px;
    grid-template-areas:"tab-bar"
                        "drawer"
                        "controller";
}
.tabs{
    display: flex;
    border: solid;
    overflow: scroll;
    border-width: 0px 0px 3px 0px;
    border-color: var(--border);
    -webkit-mask-image: linear-gradient(270deg, transparent 0%,black 2%, black 98%, transparent 100%);
    mask-image: linear-gradient(270deg, transparent 0%,black 2%, black 98%, transparent 100%);
}
.tab-bar{
    overflow: hidden;
}
.tab{
    position:relative;
    font-size: 30px;
    padding: 0px 10px;
    color: #868686;
    cursor: pointer;
    transition: .2s;
    font-size: 24px;
}
    .notification{
        position: absolute;
        height: 20px;
        width: 20px;
        top: 5px;
        right: 5px;
        background-color: var(--green);
        border-radius: 10px;
    }
        .notification.white{
            background-color: #fff !important;
        }
.tab.active{
    color:var(--green);
    border: solid;
    border-width: 0px 0px 3px 0px;
}
.nav-link {
    color: #008833;
}
.nav-link:hover {
    color: #004b1d;
}
.flip-card-icon {
    position: absolute;
    z-index: 1;
    font-size: 20px;
}
.card-back .flip-card-icon {
    left: 10px;
}
.flip-card{
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    background-color: transparent;
    position: relative;
}
    .flip-card.flipped .flip-card-inner{
        transform: rotateY(180deg);
    }
    .flip-card.flipped .card{
        pointer-events: none;
    }
        .flip-card-inner {
            position: relative;
            width: 100%;
            height: 100%;
            transition: transform 0.8s;
            transform-style: preserve-3d;
        }
.hourglass{
    position: relative;
    color: var(--bs-primary);
    font-size: 25px;
    width: 30px;
    height: 30px;
}
.hourglass i{
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
    .hourglass i:first-child{
        opacity: 1;
    }
    .hourglass.animate{
        animation: rotate .5s forwards ease-out;
        animation-delay: 1.5s;
    }
    .hourglass.animate i:nth-child(1){
        animation:unset;
    }
    .hourglass.animate i:nth-child(2){
        opacity: 0;
        animation: fadeInOut 1s;
    }
    .hourglass.animate i:nth-child(3){
        opacity: 0;
        animation: fadeInOut 1s;
        animation-delay:.5s;
    }
    .hourglass.animate i:nth-child(4){
        opacity: 0;
        animation: pulse 1s forwards;
        animation-delay:1s; 
    }

    .table-controller {
        display: flex;
        gap: 10px;
        padding: 20px;
        box-shadow: 1px 1px 11px 1px #d8d8d8;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #f4f4f4;
    }
    .table-container{
        height: 550px !important;
        border: unset !important;
        padding:10px !important;
    }
        .table-container.card.critical{
            border: 2px solid red !important;
        }
        .table-container .card-header{
            padding: 10px !important;
            background: #00000008 !important;
            border-radius: 10px !important;
            border-bottom: unset !important;
        }
        .table-container .card-body{
            font-size: 12px;
            margin: 0px -10px;
            height: auto;
            overflow: auto;
            cursor: grab;
            padding-bottom: 50px !important;
        }
            .table-container .table-dish {
                line-break: unset;
                padding: 3px 3px;
                line-height: 16px;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                font-weight: 400;
                width: 100%;
                margin-top: 3px;
                word-break: break-all;
                border: solid 2px #ffffff;
            }
                .table-dish.selected{
                    border: solid 2px var(--green) !important;
                }
                /*.table-dish.selected-in-progress{
                    border: solid 2px #000000 !important;
                }*/
                .selected-in-progress{
                    border: solid 2px #000000 !important;
                }
                /*.table-dish.in-progress{
                    background: #00df0b !important;
                    color: #fff !important;
                }*/
                .in-progress{
                    background: #00df0b !important;
                    color: #fff !important;
                }
                .table-dish.in-progress-with-done{
                    background: #ff830f !important;
                    color: #fff !important;   
                }
                    .dish-note {
                        padding: 5px;
                        line-height: 15px;
                    }
                    .dish-variations {
                        list-style: none;
                        padding: 5px 0px 5px 10px;
                        margin: 0px;
                    }
                        .dish-variations li{
                            line-height: 15px;
                        }
.flip-card .card{
    border: 3px solid transparent!important;
}
.card {
    background-color: #fff;
    border-radius: 20px!important;
    box-shadow: 0 18px 50px -10px rgb(0 0 0 / 11%);
    height: 100%;
    overflow: hidden;
    padding: 5px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: unset !important;
    width: 100%;
}
    .card.in-progress, .card.in-progress * {
        background: #00df0b !important;
        color: #fff !important;
    }
    .card.in-progress-with-done, .card.in-progress-with-done * {
        background: #ff830f !important;
        color: #fff !important;
    }
    .card.selected{
        border: solid 5px var(--green) !important;
    }
    .card.selected-in-progress{
        border: solid 5px #000000 !important;
    }
    .card-header {
        background-color: #fff;
        text-align: center;
        padding: 0 0 5px;
    }
        .card-header p:nth-child(1){
            font-size: 14px;
            font-weight: 700;
        }
        .card-header p:nth-child(3){
            font-size: 20px;
            font-weight: 400;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .card-header p:nth-child(2){
            font-size: 18px;
            font-weight: 400;
            /*height: 40px;*/
            line-height: 14px;
            min-height: 50px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            min-height: 28px;
        }
    .card-body{
        font-size: 15px;
        font-weight: 700;
        padding: 0px !important;
        height: 65px;
        flex-grow: unset;
        height: 30px;
        line-height: 30px;
    }
    .card-footer{
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        flex-grow: unset;
        padding:3px;
    }
        .card-footer :nth-child(2){
            font-weight: bold;
            position: absolute;
            bottom: 0;
            right: 0;
            background: var(--bs-primary) !important;
            padding: 7px;
            line-height: 15px;
            color: #fff !important;
            border-radius: 15px 0px 0px 0px;
        }
.card-back{
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 10px;
    gap: 10px;
    text-align: center;
    align-content: start;
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 20px;
}
    .card-back :nth-child(2) {
        margin-top: 25px;
    }
        .card-back-status{
            border: solid 1px var(--bs-primary);
            color: var(--bs-primary);
            padding: 5px;
            margin: 5px;
            height: fit-content;
            flex-grow: 1;
            cursor: pointer;
            line-break: anywhere;
        }
            .card-back-status:hover{
                border: solid 1px var(--bs-primary);
                background-color: var(--bs-primary);
                color: #fff;
            }
.spinner-container{
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 20px;
    background-color: var(--green);
    border-radius: 20px;
    /*border: 1px solid var(--green);*/
}
    .spinner-border.text-success {
        left: 50%;
        top: 50%;
        padding: 20px;
        color: white !important;
    }

.table>thead {
    vertical-align: bottom;
    position: sticky;
    top: 0;
    background: var(--green);
    color: #fff;
    box-shadow: 1px 1px 11px 1px #d8d8d8;
}

.table {
    margin-bottom: 100px;
}

.table-controller {
    display: flex;
    gap: 10px;
    padding: 20px;
    box-shadow: 1px 1px 11px 1px #d8d8d8;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #f4f4f4;
}

.controller-buttons{
    align-items: center;
    background-color: var(--green);
    border-radius: 100px;
    box-shadow: 0 18px 50px -10px rgb(0 0 0 / 20%);
    color: #fff;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    justify-content: center;
    margin: 10px;
    padding: 20px;
    position: relative;
    width: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.extra-settings {
    width: auto;
    height: 50px;
    position: absolute;
    top: 10px;
    right: 150px;
    display: flex;
    gap: 10px;
    align-items: center;
    z-index: 100;
}
.controller-buttons .button{
    padding: 5px 10px;
    border: solid;
    line-height: 17px;
    font-weight: 400;
    border-width: 0px 2px 0px 0px;
    border-color: #fff;
    cursor: pointer;
    text-align: center;
}
    .controller-buttons .button:nth-last-child(1){
        border: unset !important;
    }
.setting-popover{
    position: absolute;
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f4f4f4;
}
.setting{
    color:#fff;
    background:var(--green);
    border-radius: 100px;
    height: 60px;
    font-size: 25px;
    width: 60px;
    display: flex;
    justify-content:center;
    align-items: center;
    position: absolute;
    left: -70px;
    cursor: pointer;
}
.settings-page{
   display: flex;
   flex-direction: column;
}
.settings-page .nav-item {
    margin: 0px 5px;
}
.settings-page #settingTabContent{
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}
.settings-page #settingTabContent .status-setting{
    box-shadow: unset !important;
    border: solid rgb(235, 235, 235) 1px;
}

.settings-page .tabs{
    mask-image: unset;
    -webkit-mask-image:unset;
}
    .setting-container p{
        margin-bottom: 10px;
        font-size: 18px;
        color: #737373;
    }
    .settings-page .tab-bar {
        overflow: hidden;
        background: #fff;
        border-radius: 20px;
        box-shadow: rgb(0 0 0 / 5%) 0px 10px 20px 0px;
    }
        .settings-page .tab {
           font-size: 25px;
           border-color: 0px 0px 4px 0px;
        }
                .settings-page .tab-bar:hover .tabs{
                    border-color: transparent;
                }
                .tab:active{
                    box-shadow: rgb(0 0 0 / 5%) -2px 5px 11px 0px !important;
                }
    .status-setting{
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: #fff;
        border-radius: 20px;
        box-shadow: rgb(0 0 0 / 5%) 0px 10px 20px 0px;
    }
.settings-page.vertical{
    flex-direction: row-reverse;
    justify-content: center;
}
    .settings-page.vertical .tabs {
        flex-direction: column;
        border-width: 0px 0px 0px 2px;
    }
        .settings-page.vertical .tab.active {
            border-width: 0px 0px 0px 7px;
        }
            .settings-page.vertical .status-preview{
                width: 20%;
                max-width: 300px;
            }
            .settings-page.vertical .add-status{
                width: 80%;
            }
            .tab.over {
                border: 1px dotted #666 !important;
              }

.login-page{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
   align-items: center;
}
    .login-form{
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: #fff;
        border-radius: 20px;
        box-shadow: rgb(0 0 0 / 5%) 0px 10px 20px 0px;
        width: 90%;
        max-width: 550px;
    }

/* responsive */

@media all and (max-width:1200px) {
    .settings-page.vertical  .status-preview, .settings-page.vertical .add-status{
        width: 100%;
        max-width: unset;
    }
    .settings-page{
        justify-content: center;
    }
        .settings-page .tabs {
            flex-direction: column;
            border-width: 0px 0px 0px 2px;
        }
            .settings-page .tab.active {
                border-width: 0px 0px 0px 7px;
            }
                .settings-page .status-preview{
                    width: 100%;
                    max-width: unset;
                }
                .settings-page .add-status{
                    width: 100%;
                }
                .tab.over {
                    border: 1px dotted #666 !important;
                  }
}

/* responsive */

/* animazioni */

.openPopover{
    animation: openPopover;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    animation-fill-mode: forwards;
}
.pulse{
    animation: pulse;
    animation-timing-function: ease-out;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}
@keyframes openPopover {
    0%{top:100%}
    100%{top:0}
}
@keyframes pulse {
    0%{opacity:0;}
    100%{opacity:1;}
}
@keyframes fadeInOut {
    0%{opacity:0;}
    50%{opacity:1;}
    100%{opacity:0;}
}
@keyframes rotate {
    0%{transform: rotate(0deg);}
    100%{transform: rotate(180deg);}
}
@keyframes fadeIn {
    0%{opacity:0;}
    100%{opacity:1;}
}
/* animazioni */